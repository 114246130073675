// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-hotel-near-holy-family-bandra-hospital-js": () => import("./../../../src/pages/hotel-near-holy-family-bandra-hospital.js" /* webpackChunkName: "component---src-pages-hotel-near-holy-family-bandra-hospital-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lilavati-bandra-hospital-near-hotel-in-bandra-js": () => import("./../../../src/pages/lilavati-bandra-hospital-near-hotel-in-bandra.js" /* webpackChunkName: "component---src-pages-lilavati-bandra-hospital-near-hotel-in-bandra-js" */),
  "component---src-pages-occupancy-js": () => import("./../../../src/pages/occupancy.js" /* webpackChunkName: "component---src-pages-occupancy-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-rooms-royal-room-hotel-in-bandra-mumbai-js": () => import("./../../../src/pages/rooms/royal-room-hotel-in-bandra-mumbai.js" /* webpackChunkName: "component---src-pages-rooms-royal-room-hotel-in-bandra-mumbai-js" */),
  "component---src-pages-seven-hills-hospital-near-hotel-in-marol-andheri-bandra-mumbai-js": () => import("./../../../src/pages/seven-hills-hospital-near-hotel-in-marol-andheri-bandra-mumbai.js" /* webpackChunkName: "component---src-pages-seven-hills-hospital-near-hotel-in-marol-andheri-bandra-mumbai-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-zeta-party-banquet-conference-hall-bandra-west-mumbai-js": () => import("./../../../src/pages/zeta-party-banquet-conference-hall-bandra-west-mumbai.js" /* webpackChunkName: "component---src-pages-zeta-party-banquet-conference-hall-bandra-west-mumbai-js" */)
}

